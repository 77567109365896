<template>
    <v-container>
        <v-card color="error darken-1">
            <v-card-title>
                <h3>
                    <v-icon large>
                        mdi-alert-circle
                    </v-icon>


                    Pago Fallido
                </h3>
            </v-card-title>
            <v-card-text style="font-size: 18px;">
                
                El pago no fue procesado con exito, por favor intente nuevamente.

                <v-btn class="my-4" color="primary darken-1" rounded block x-large @click="$router.push('/checkout')">
                    <v-icon  left>
                        mdi-reload
                    </v-icon>
                    Reintentar Pago
                </v-btn>
                Si el problema persiste, por favor contacte al administrador.



                <v-btn rounded
    class="mx-auto my-4"
    color="green"
    dark
    x-large
    block
    href="https://wa.me/+59898996900?text=Hola!%20Quiero%20renovar%20mi%20suscripción"
    target="_blank"
    >
    <v-icon left color="white">
        mdi-whatsapp
    </v-icon>
    Contacto por Whatsapp
    </v-btn>
            </v-card-text>


          
        </v-card>

        <v-btn class="mt-4" color="primary darken-1" text block x-large @click="$router.push('/inicio')">
                    <v-icon  left>
                        mdi-home
                    </v-icon>
                    Volver a Inicio</v-btn>

    </v-container>
</template>

<script>
//import firebase functions
import { getFunctions, httpsCallable } from "firebase/functions";

//import firestore adddoc doc collection,etc
import { getFirestore, collection, addDoc, setDoc, updateDoc, doc } from "firebase/firestore";
import moment from 'moment';

export default {
    data() {
        return {

        };
    },
    

}
</script>

<style></style>